
import { defineComponent } from "vue";
import { Team, Event } from "../../types";
import { SessionSelected } from "../../-types";
import { PropType } from "vue";
import TeamsService from "@/services/TeamsService";
import EditableTeamMember from "./EditableTeamMember.vue";
import AddTeamMember from "./AddTeamMember.vue";
import { useToast, TYPE } from "vue-toastification";
import { TeamMember } from "./-types";
import {
  getStrippedTeamName,
  sendGtagEvent,
  shareLinkToTeam,
} from "@/common/common";
import QrcodeVue from "qrcode.vue";

export default defineComponent({
  data() {
    const rightNow: Date = new Date();
    // should change this on resize event but meh for now
    let innerWidth = window.innerWidth;

    return {
      rightNow,
      innerWidth,
      showQR: false as boolean,
      lat: null as string,
      long: null as string,
      startDateTime: null as string | null,
      choiceVoteEndDateTime: null as Date | null,
      restaurantVoteEndDateTime: null as Date | null,
      votingDatesDisabled: true as boolean,
      location: "" as string,
      eventName: "" as string,
      sessionSelected: "" as SessionSelected,
      teamMembers: [] as TeamMember[],
      getStarted: false as boolean,
      editing: false as boolean,
    };
  },

  computed: {
    linkToTeam() {
      const strippedTeamName = getStrippedTeamName(this.team.teamName);
      return (
        "https://www.gophrr.io/join/" +
        (this.team?.uuidPrimaryKey ?? "") +
        "/" +
        strippedTeamName
      );
    },
    validEvents() {
      if (this.eventsLoaded) {
        return this.events.filter(
          (e) =>
            !e.cancelled &&
            this.rightNow.getTime() <
              new Date(e.eventStartDateTime).getTime() &&
            e.teamId === this.team.uuidPrimaryKey
        );
      } else {
        return [];
      }
    },
  },
  props: {
    team: null as PropType<Team>,
    index: Number,
    events: Array as PropType<Event[]>,
    eventsLoaded: Boolean,
    teamsLoaded: Boolean,
  },
  components: {
    EditableTeamMember,
    AddTeamMember,
    QrcodeVue,
  },
  methods: {
    async shareLink() {
      sendGtagEvent(this.$gtag, "share_link_to_team", {
        eventLabel: "user_shared_link",
        event_category: "engagement",
        id: this.team.uuidPrimaryKey,
      });
      await shareLinkToTeam(this.team.uuidPrimaryKey, this.team.teamName);
    },
    async actuallyDeleteTeam(): Promise<void> {
      try {
        if (!this.team.uuidPrimaryKey) {
          const toast = useToast();
          toast("Problem deleting this team", {
            timeout: 2500,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          const response = await TeamsService.deleteTeam(
            this.$store.state.userFirebaseUid,
            this.team.uuidPrimaryKey
          );
          if (response.status === 200) {
            const toast = useToast();
            toast('Successfully deleted team "' + this.team.teamName + '".', {
              timeout: 2500,
              type: TYPE.SUCCESS,
              hideProgressBar: true,
            });
            this.$emit("refresh-teams");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addTeamMember(member: string, key: string): Promise<void> {
      try {
        if (!member || !key) {
          const toast = useToast();
          toast("Enter a Team Member to add.", {
            timeout: 2500,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          const response = await TeamsService.addTeamMember(
            member,
            key,
            this.$store.state.userFirebaseUid
          );
          if (response.status === 200) {
            const toast = useToast();
            toast("Successfully added " + member + " to the team.", {
              timeout: 2500,
              type: TYPE.SUCCESS,
              hideProgressBar: true,
            });
            this.getTeamMembers();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async removeTeamMember(
      teamMemberFirebaseUid: string,
      key: string
    ): Promise<void> {
      try {
        const response = await TeamsService.removeTeamMember(
          teamMemberFirebaseUid,
          key,
          this.$store.state.userFirebaseUid
        );
        if (response.status === 200) {
          const toast = useToast();
          toast("Successfully removed user from the team.", {
            timeout: 2500,
            type: TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.getTeamMembers();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTeam(): Promise<void> {
      if (
        await confirm(
          "Are you sure you want to delete the team " +
            this.team.teamName +
            "? THIS WILL ALSO DELETE ALL EVENTS ASSOCIATED WITH THIS TEAM."
        )
      ) {
        this.$nextTick(() => {
          this.actuallyDeleteTeam();
        });
      }
    },
    // async findSession(teamKey): Promise<void> {
    //   try {
    //     const response = await SessionService.findTeamSession(teamKey);
    //     // console.log(JSON.parse(response.data.session))
    //     var newSession = JSON.parse(response.data.session);
    //     var newSessionId = newSession.sessionId;
    //     // console.log(newSessionId)
    //     this.$router.push({
    //       name: "StartSession",
    //       params: { sessionId: newSessionId },
    //     });
    //   } catch (error) {
    //     console.log("error: " + error);
    //   }
    // },
    async sendNotification(id: string) {
      await TeamsService.testSendTeamNotification(id);
    },
    async getTeamMembers(): Promise<void> {
      this.$emit("refresh-teams");
    },
  },
  emits: ["refresh-teams"],
});
