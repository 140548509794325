
import TeamSessionContainer from "./Team/TeamSessionContainer.vue";
import { defineComponent } from "vue";
import UserService from "@/services/UserService";
import { Team, Event } from "../types";
import { showCreateNewTeamModal } from "./Modals/ModalController";
import eventBus from "./EventBus";
import { getTeams } from "@/common/common";
import refreshMixin from "@/mixins/refreshMixin";
import { userStatusMixin } from "@/mixins/isUserLoggedInMixin";

export default defineComponent({
  mixins: [refreshMixin, userStatusMixin],
  data() {
    return {
      teams: [] as Team[],
      events: [] as Event[],
      eventsLoaded: false as boolean,
      showReturnToGetStartedButton: false as boolean,
      error: Error,
      invitees: "" as string,
      teamName: "" as string,
      getStarted: false as boolean,
    };
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.getTeams();
    await this.getUserEvents();
    eventBus.$on("team-created", async () => {
      await this.getTeams();
    });
    await this.$store.dispatch("setLoading", false);
  },
  components: {
    TeamSessionContainer,
  },
  beforeRouteLeave() {
    eventBus.$off("team-created");
    eventBus.$off("start");
    return true;
  },
  methods: {
    async refresh() {
      await this.getTeams();
      await this.getUserEvents();
      this.completeRefresh();
    },
    showCreateNewTeamModal,
    async refreshTeams() {
      await this.getTeams();
    },
    async refreshEvents() {
      await this.getUserEvents();
    },
    async getUserEvents() {
      try {
        const response = await UserService.getUserEvents(
          this.$store.state.userFirebaseUid,
          false
        );
        this.events = response.data.eventsAndTeamNames;
      } catch (e) {
        console.log(e);
      } finally {
        this.eventsLoaded = true;
      }
    },

    async getTeams(): Promise<void> {
      try {
        if (this.isUserLoggedIn) {
          this.teams = await getTeams();
          this.noTeams = this.teams.length === 0;
        }
      } catch (error) {
        console.error("ERROR" + error);
      }
    },
  },
});
