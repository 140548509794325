
import { defineComponent } from "vue";
export default defineComponent({
  computed: {
    flairForYouClass() {
      return this.teamMemberFirebaseUid === this.$store.state.userFirebaseUid
        ? "badge border border-primary text-primary rounded-pill p-1 me-1"
        : "";
    },
    flairForYouText() {
      return this.teamMemberFirebaseUid === this.$store.state.userFirebaseUid
        ? "You"
        : "";
    },
    flairForCreatorClass() {
      return this.teamMemberFirebaseUid === this.creatorUid
        ? "badge border border-secondary text-secondary rounded-pill p-1"
        : "";
    },
    flairForCreatorText() {
      return this.teamMemberFirebaseUid === this.creatorUid ? "Creator" : "";
    },
    outerStyle() {
      return this.flairForYouClass || this.flairForCreatorText
        ? "li badge fw-semibold fs-6 bg-grayish border border-grayish text-dark list-group-item w-100 position-relative rounded-3 p-4 m-1"
        : "li badge fw-semibold fs-6 bg-grayish border border-grayish text-dark list-group-item w-100 position-relative rounded-3 m-1";
    },
  },
  props: {
    teamMemberName: {
      type: String,
      required: true,
    },
    teamMemberFirebaseUid: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    teamUid: {
      type: String,
      required: true,
    },
    creatorUid: {
      type: String,
      required: true,
    },
  },
  methods: {
    async removeTeamMember() {
      if (this.editing) {
        if (
          await confirm(
            "Are you sure you want to remove " + this.teamMemberName + "?"
          )
        ) {
          this.$emit(
            "remove-team-member",
            this.teamMemberFirebaseUid,
            this.teamUid
          );
        }
      }
    },
  },
});
