
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      name: "" as string,
    };
  },
  props: {
    editing: { type: Boolean, required: true },
    teamUid: { type: String, required: true },
  },
  methods: {
    addTeamMember() {
      this.$emit("add", this.name, this.teamUid);
      this.name = "";
    },
  },
});
